<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('application_withdraw_display')" :is-filter="false"/>
            </template>
            <template v-slot:header-mobile>
                <Header-mobile :title="$t('application_withdraw_display')" :isFilter="false"/>
            </template>
            <div>
                <h5 class="mt-1 mb-3">{{ $t('student_information').toUpper() }}</h5>
                <info-collapse-area>
                    <template #title>
                        <h5>{{$t("detail")}}</h5>
                    </template>
                    <template #detail>
                       <student-info :data="student"/>
                    </template>
                </info-collapse-area>

                <datatable
                    @on-page-change="onPageChange"
                    @on-per-page-change="onPerPageChange"
                    :rows="datatable.rows"
                    :columns="datatable.columns"
                    :query-params="datatable.queryParams"
                    v-show="datatable.showTable"
                    :is-loading="datatable.isLoading"
                    :title="$t('courses_you_have_taken_so_for')"
                ></datatable>

            </div>
        </app-layout>
    </div>
</template>
<script>
import AppLayout from "@/layouts/AppLayout";
import Header from "@/layouts/AppLayout/Header";
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile";

import Datatable from "@/components/datatable/Datatable";

import WithDrawService from "@/services/WithDrawService";
import InfoCollapseArea from "@/components/elements/InfoCollapseArea";
import StudentInfo from "@/modules/withDraw/pages/studentInfo";

export default {
    components: {StudentInfo, InfoCollapseArea, Datatable, HeaderMobile, Header, AppLayout},
    data() {
        return {
            trainee: {},
            student: {},
            datatable: {
                columns: [
                    {
                        label: "",
                        field: 'buttons',
                        html: true,
                        sortable: false,
                        tdClass: 'p-0 text-center w-40 align-middle',
                        buttons: [
                            {
                                text: this.$t('do_withdraw'),
                                class: 'ri-add-line align-middle top-minus-1 mr-3 text-muted',
                                callback: (row) => {
                                    this.addToWithDraw(row.id)
                                },
                                permission: "withdraw_store"
                            }
                        ]
                    },
                    {field: "student_slot_semester", label: this.toUpperCase("semester"), hidden: false, sortable: false},
                    {field: ("slot_type_name"), label: this.toUpperCase("slot_type"), hidden: false, sortable: false},
                    {field: "course_code", label: this.toUpperCase("course_code"), hidden: false, sortable: false},
                    {field: ("course_name"), label: this.toUpperCase("course_name"), hidden: false, sortable: false},
                    {field: "credit", label: this.toUpperCase("credit"), hidden: false, sortable: false},
                ],
                showTable: false,
                isLoading: false,
                rows: [],
                queryParams: {
                    filter: {
                        student_number: null
                    },
                    page: 1,
                    limit: 20
                }
            }
        }
    },
    created() {
        this.getRows().then(() => {
            this.datatable.queryParams.filter.student_number = this.student.student_number;
        })
    },
    methods: {
        getRows() {

            this.datatable.isLoading = true;
            this.datatable.showTable = true;

            return WithDrawService.getStudentCourse()
                .then((res) => {
                        this.student = res.data.data.student;
                        this.datatable.rows = res.data.data.courses
                        this.datatable.total = res.data.pagination.total
                    }
                ).catch(e => this.showErrors(e))
                .finally(() => this.datatable.isLoading = false)
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows()
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1
            this.getRows()
        },
        addToWithDraw(id) {
            if (this.checkPermission("withdraw_store")) {
                this.$swal.fire({
                    text: this.$t('are_you_sure_to_withdraw'),
                    showCancelButton: true,
                    confirmButtonText: this.$t('yes'),
                    cancelButtonText: this.$t('no')
                }).then((result) => {
                    if (result.isConfirmed) {
                        let formData = {student_course_id: id}
                        WithDrawService.store(formData).then(res => this.$toast.success(this.$t("api." + res.data.message))).catch(e => this.showErrors(e))
                    }
                })
            } else return
        }
    },
}
</script>
